function ValuesMap({ label, onChange, value, constraints }) {
  const changeHandler = (constraint) => (event) => {
    event.preventDefault();
    value[constraint.payloadField] = event.currentTarget.value;
    onChange(value);
  };

  const inputRows = (
    <fieldset>
      <legend>{label}</legend>
      {constraints.map((c) => {
        return (
          <div key={c.name}>
            <label htmlFor={`${c.name}-input`}>{c.name}</label>
            <input
              id={`${c.name}-input`}
              placeholder="data alias eg: flow_rate"
              value={value[c.payloadField] || ""}
              onChange={changeHandler(c)}
            ></input>
          </div>
        );
      })}
    </fieldset>
  );

  return <div>{inputRows}</div>;
}

export default ValuesMap;
