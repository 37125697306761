import { useEffect, useState } from "react";
import errorLogger from "../../helpers/errorLogger";
import useApi from "../../helpers/useApi";
import DropdownConstraints from "../elements/parameter_constraints/DropdownConstraints";
import NumberConstraints from "../elements/parameter_constraints/NumberConstraints";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ValueMapConstraints from "../elements/parameter_constraints/ValueMapConstraints";

const Parameters = () => {
  const [paramsList, setParamsList] = useState({
    data: [],
    loading: true,
    error: null,
  });
  const [inputName, setInputName] = useState("");
  const [inputCategory, setInputCategory] = useState("");
  const [inputType, setInputType] = useState("");
  const [inputValueConstraints, setInputValueConstraints] = useState([]);

  const { callApi } = useApi();

  const resetInputs = () => {
    setInputName("");
    setInputCategory("");
    setInputType("");
    setInputValueConstraints({});
  };

  const handleCancel = (_event) => resetInputs();

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const requestBody = {
        name: inputName,
        category: inputCategory,
        inputType: inputType,
        inputConstraints: inputValueConstraints,
      };
      const options = {
        path: "/parameters",
        scope: "create:parameters",
        method: "POST",
        body: JSON.stringify(requestBody),
      };
      const newParam = await callApi(options).then((res) => res.json());
      const newList = [...paramsList.data, newParam];
      setParamsList({
        ...paramsList,
        data: newList,
      });
      resetInputs();
    } catch (error) {
      errorLogger(error);
    }
  };

  const handleDelete = async (param, event) => {
    event.preventDefault();

    try {
      const options = {
        path: `/parameters/${param.id}`,
        scope: "delete:parameters",
        method: "DELETE",
      };
      await callApi(options);
      const newList = paramsList.data.filter((p) => p.id !== param.id);
      setParamsList({
        ...paramsList,
        data: newList,
      });
    } catch (error) {
      errorLogger(error);
    }
  };

  useEffect(() => {
    const updateParamsList = async () => {
      try {
        const options = {
          path: "/parameters",
          scope: "list:parameters",
        };
        const params = await callApi(options).then((res) => res.json());
        setParamsList({
          ...paramsList,
          data: params,
          error: null,
          loading: false,
        });
      } catch (error) {
        errorLogger(error);
        setParamsList({
          ...paramsList,
          error,
          loading: false,
        });
      }
    };

    updateParamsList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const actionBuilder = (param) => {
    return (
      <td>
        <button onClick={(e) => handleDelete(param, e)}>
          <FontAwesomeIcon icon="fa-solid fa-trash" />
        </button>
      </td>
    );
  };

  const paramRows = paramsList.loading ? (
    <tr>
      <td colSpan="5">Loading...</td>
    </tr>
  ) : (
    paramsList.data
      ?.filter(
        (param) =>
          param.name.toLowerCase().includes(inputName.toLowerCase()) &&
          (inputCategory ? param.category === inputCategory : true)
      )
      .map((param) => (
        <tr key={param.id}>
          <td>{param.name}</td>
          <td>{param.category}</td>
          <td>{param.inputType}</td>
          {actionBuilder(param)}
        </tr>
      ))
  );

  return (
    <div className="Parameters page">
      <h2>Parameters</h2>
      <form onSubmit={handleSubmit}>
        <div>
          <label htmlFor="parameters-name">Name</label>
          <input
            id="parameters-name"
            type="text"
            value={inputName}
            onChange={(e) => setInputName(e.currentTarget.value)}
          />
        </div>
        <div>
          <label htmlFor="parameters-category">Category</label>
          <select
            id="parameters-category"
            value={inputCategory}
            onChange={(e) => setInputCategory(e.currentTarget.value)}
          >
            <option value="">Select an Owner...</option>
            <option key="model" value="device model">
              Device Model
            </option>
            <option key="type" value="device type">
              Device Type
            </option>
          </select>
        </div>
        <div>
          <label htmlFor="parameters-type">Input Type</label>
          <select
            id="parameters-type"
            value={inputType}
            onChange={(e) => setInputType(e.currentTarget.value)}
          >
            <option value="">Select a Type...</option>
            <option key="number" value="number">
              Number
            </option>
            <option key="dropdown" value="dropdown">
              Dropdown
            </option>
            <option key="calibrationFile" value="calibration file">
              Calibration File
            </option>
            <option key="valuesMap" value="values map">
              Values Map
            </option>
          </select>
        </div>
        {inputType === "number" ? (
          <NumberConstraints
            {...{ inputValueConstraints, setInputValueConstraints }}
          />
        ) : inputType === "dropdown" ? (
          <DropdownConstraints
            {...{ inputValueConstraints, setInputValueConstraints }}
          />
        ) : inputType === "values map" ? (
          <ValueMapConstraints
            {...{ inputValueConstraints, setInputValueConstraints }}
          />
        ) : (
          <></>
        )}
        <div></div>
        <input type="submit" value="Save" />
        <input type="button" value="Cancel" onClick={handleCancel} />
      </form>

      <table>
        <thead>
          <tr>
            <th>Name</th>
            <th>Category</th>
            <th>Input Type</th>
            <th></th>
          </tr>
        </thead>
        <tbody>{paramRows}</tbody>
      </table>
    </div>
  );
};

export default Parameters;
