import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import errorLogger from "../../../helpers/errorLogger";
import useApi from "../../../helpers/useApi";
import LocationInput from "../../elements/LocationInput";
import ParameterInput from "../../elements/ParameterInput";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function EditDevice() {
  const { user } = useAuth0();
  const userRoles = user?.cyf_roles;
  const isAdmin = userRoles && userRoles.includes("Admin");

  const params = useParams();
  const { callApi } = useApi();
  const navigate = useNavigate();

  const [device, setDevice] = useState({
    data: {},
    loading: true,
    error: null,
  });

  const [typesList, setTypesList] = useState({
    data: [],
    loading: true,
    error: null,
  });

  const [clientsList, setClientsList] = useState({
    error: null,
    loading: true,
    data: [],
  });

  const [manufacturersList, setManufacturersList] = useState({
    error: null,
    loading: true,
    data: [],
  });

  const [modelsList, setModelsList] = useState({
    error: null,
    loading: false,
    data: [],
  });

  const [paramsList, setParamsList] = useState({
    error: null,
    loading: false,
    data: [],
  });

  const [formEui, setFormEui] = useState("");
  const [formName, setFormName] = useState("");
  const [formClient, setFormClient] = useState("");
  const [formManufacturer, setFormManufacturer] = useState("");
  const [formModel, setFormModel] = useState("");
  const [formDeviceType, setFormDeviceType] = useState("");
  const [formIntent, setFormIntent] = useState("update");
  const [formLocation, setFormLocation] = useState({});

  const [formParams, setFormParams] = useState({});

  const fetchDevice = async () => {
    try {
      const options = {
        path: `/devices/${params.deviceId}`,
        scope: "read:devices",
      };
      const newDevice = await callApi(options).then((res) => res.json());

      setDevice({
        ...device,
        data: newDevice,
        error: null,
        loading: false,
      });
      setFormEui(newDevice.eui);
      setFormName(newDevice.name);
      setFormClient(newDevice.clientId);
      setFormManufacturer(newDevice.manufacturerId);
      setFormModel(newDevice.deviceModelId);
      setFormLocation(newDevice.location);
      setFormDeviceType(newDevice.deviceTypeId);
      setFormParams(newDevice?.parameters || []);
    } catch (error) {
      errorLogger(error);
      setDevice({
        ...device,
        error,
        loading: false,
      });
    }
  };

  const updateTypesList = async () => {
    try {
      const options = {
        path: "/device_types",
        scope: "list:device_types",
      };
      const newTypes = await callApi(options).then((res) => res.json());
      setTypesList({
        ...typesList,
        data: newTypes,
        error: null,
        loading: false,
      });
    } catch (error) {
      errorLogger(error);
      setTypesList({
        ...typesList,
        error,
        loading: false,
      });
    }
  };

  const updateClientsList = async () => {
    try {
      const options = {
        path: "/clients",
        scope: "list:clients",
      };
      const newClients = await callApi(options).then((res) => res.json());
      setClientsList({
        ...clientsList,
        data: newClients,
        error: null,
        loading: false,
      });
    } catch (error) {
      errorLogger(error);
      setClientsList({
        ...clientsList,
        error,
        loading: false,
      });
    }
  };

  const updateManufacturersList = async () => {
    try {
      const options = {
        path: "/manufacturers",
        scope: "list:manufacturers",
      };
      const res = await callApi(options);
      setManufacturersList({
        ...manufacturersList,
        data: await res.json(),
        error: null,
        loading: false,
      });
    } catch (error) {
      errorLogger(error);
      setManufacturersList({
        ...manufacturersList,
        error,
        loading: false,
      });
    }
  };

  const updateModelsList = async () => {
    try {
      const options = {
        path: "/device_models",
        scope: "list:device_models",
        query: { manufacturerId: formManufacturer },
      };
      const res = await callApi(options);
      setModelsList({
        data: await res.json(),
        error: null,
        loading: false,
      });
    } catch (error) {
      errorLogger(error);
      setModelsList({
        ...modelsList,
        error,
        loading: false,
      });
    }
  };

  useEffect(() => {
    if (isAdmin && !modelsList.loading && !!formManufacturer) {
      setModelsList({
        ...modelsList,
        loading: true,
      });
      updateModelsList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formManufacturer]);

  useEffect(() => {
    if (
      formParams &&
      formParams["Default Location"] &&
      !formParams["Default Location"].latitude
    ) {
      const clientLocation = clientsList.data.find(
        (c) => c.clientName === formClient
      ).location;
      setFormParams({
        ...formParams,
        "Default Location": clientLocation,
      });
    }
  }, [clientsList, formParams, formClient]);

  const updateParamsList = async () => {
    try {
      const options = {
        path: `/parameters`,
        scope: "list:parameters",
        query: {
          deviceTypeId: device.data.deviceTypeId,
          deviceModelId: device.data.deviceModelId,
        },
      };
      const fetchedParams = await callApi(options).then((resp) => resp.json());

      console.log("fetched params", fetchedParams);

      setParamsList({
        ...paramsList,
        data: fetchedParams,
        error: null,
        loading: false,
      });
    } catch (error) {
      errorLogger(error);
      setParamsList({
        ...paramsList,
        error,
        loading: false,
      });
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setDevice({
      ...device,
      loading: true,
    });

    // TODO: For now we just send everything up in an update, but we could
    // be smarter about this and only send updated values

    const deviceUpdates = {
      eui: formEui,
      name: formName.trim(),
      clientId: formClient,
      location: formLocation,
      deviceTypeId: formDeviceType,
      deviceModelId: formModel,
      parameters: formParams,
    };

    try {
      const options = {
        path: `/devices/${params.deviceId}`,
        scope: "update:devices",
        method: "PUT",
        body: JSON.stringify(deviceUpdates),
      };
      const updatedDevice = await callApi(options).then((res) => res.json());
      if (updatedDevice) {
        setDevice({
          ...device,
          data: {
            ...device.data,
            ...updatedDevice,
          },
          loading: false,
        });
      } else {
        throw new Error("Failed to update device");
      }
    } catch (error) {
      errorLogger(error);
    }
  };

  const handleCancel = () => navigate(`/devices`);

  useEffect(() => {
    fetchDevice();
    if (isAdmin) {
      updateTypesList();
      updateClientsList();
      updateManufacturersList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      !paramsList.loading &&
      !!formDeviceType &&
      !device.loading &&
      !!device.data.deviceModelId
    ) {
      setParamsList({
        ...paramsList,
        loading: true,
      });
      updateParamsList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formDeviceType, device]);

  const paramChangeHandler = (param) => (value) => {
    setFormParams({
      ...formParams,
      [param]: typeof value == "string" ? value.trim() : value,
    });
  };

  const userEditableParams = [
    "Offset",
    "Tank Length",
    "Liquid Density",
    "Elliptical Height",
    "Elliptical Width",
    "Empty Level",
    "Full Level",
    "Tank Radius",
    "Tank Height",
  ];

  const buildParamsFields = () => {
    if (!!paramsList.data) {
      return paramsList.data
        .map((param) => {
          if (isAdmin || userEditableParams.includes(param.name)) {
            return (
              <ParameterInput
                key={param.id}
                value={formParams[param.id]}
                onChange={paramChangeHandler(param.id)}
                {...param}
              />
            );
          } else {
            return null;
          }
        })
        .filter((e) => !!e);
    } else {
      return <></>;
    }
  };

  const buildModelInput = () => {
    if (manufacturersList.loading || modelsList.loading) {
      return null;
    }
    if (manufacturersList.data.length === 0 || modelsList.data.length === 0) {
      return null;
    }
    return (
      <>
        <div>
          <label>Manufacturer</label>
          <select
            value={formManufacturer}
            onChange={(e) => setFormManufacturer(e.currentTarget.value)}
            disabled
          >
            {manufacturersList.data.map((m) => (
              <option key={m.id} value={m.id}>
                {m.name}
              </option>
            ))}
          </select>
        </div>
        <div>
          <label>Device Model</label>
          <select
            value={formModel}
            onChange={(e) => setFormModel(e.currentTarget.value)}
            disabled
          >
            {modelsList.data.map((m) => (
              <option key={m.id} value={m.id}>
                {m.name}
              </option>
            ))}
          </select>
        </div>
      </>
    );
  };

  return (
    <div className="EditDevice page">
      {device.loading ? (
        <h2>...Loading...</h2>
      ) : (
        <>
          <h2>{device.data.name}</h2>
          <button
            onClick={() => navigate(`/devices/${params.deviceId}/alerts`)}
          >
            <FontAwesomeIcon icon="fa-solid fa-bell" /> Device Alerts
          </button>
          <form onSubmit={handleSubmit}>
            {isAdmin && (
              <div>
                <label>Type of change</label>
                <select
                  value={formIntent}
                  onChange={(e) => setFormIntent(e.currentTarget.value)}
                >
                  <option value="update">UPDATE - update this device</option>
                  <option value="move">
                    MOVE - preserve this data, and use this device for a new
                    task
                  </option>
                  <option value="reactivate">
                    REACTIVATE - preserve this data, but move back to a previous
                    task
                  </option>
                </select>
              </div>
            )}
            <div>
              <label>EUI</label>
              <input
                type="text"
                value={formEui}
                onChange={(e) => setFormEui(e.currentTarget.value)}
                disabled
              ></input>
            </div>
            <div>
              <label>Name</label>
              <input
                type="text"
                value={formName}
                onChange={(e) => setFormName(e.currentTarget.value)}
              ></input>
            </div>
            {isAdmin ? (
              <div>
                <label>Client</label>
                <select
                  value={formClient}
                  onChange={(e) => setFormClient(e.currentTarget.value)}
                >
                  {clientsList.data.map((c) => (
                    <option key={c.clientId} value={c.clientId}>
                      {c.clientName}
                    </option>
                  ))}
                </select>
              </div>
            ) : (
              <></>
            )}
            <div>
              <LocationInput
                label={"Location"}
                value={formLocation}
                onChange={setFormLocation}
              />
            </div>
            {isAdmin && buildModelInput()}
            {isAdmin && (
              <div>
                <label>Device Type</label>
                <select
                  value={formDeviceType}
                  onChange={(e) => setFormDeviceType(e.currentTarget.value)}
                >
                  {typesList.data.map((t) => (
                    <option key={t.id} value={t.id}>
                      {t.name}
                    </option>
                  ))}
                </select>
              </div>
            )}
            <div>
              {paramsList.loading ? (
                <div>...Loading...</div>
              ) : (
                buildParamsFields()
              )}
            </div>
            <input type="submit" value="Save" />
            {isAdmin ? (
              <input type="button" value="Cancel" onClick={handleCancel} />
            ) : (
              <></>
            )}
          </form>
        </>
      )}
    </div>
  );
}

export default EditDevice;
