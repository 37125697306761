import { useEffect, useState } from "react";
import errorLogger from "../../../helpers/errorLogger";
import useApi from "../../../helpers/useApi";
import ComboBox from "../../elements/ComboBox";
import { Item } from "react-stately";
import Button from "../../elements/Button";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function DevicesList() {
  const { callApi } = useApi();
  const navigate = useNavigate();

  const [deviceList, setDeviceList] = useState({
    data: [],
    loading: true,
    error: null,
  });

  const [typesList, setTypesList] = useState({
    data: [],
    loading: true,
    error: null,
  });

  const [clientsList, setClientsList] = useState({
    error: null,
    loading: true,
    data: [],
  });

  const [showActive, setShowActive] = useState(`["active"]`);
  const [showHidden, setShowHidden] = useState(`["visible"]`);

  const [euiFilter, setEuiFilter] = useState({ value: "", key: "" });
  const [nameFilter, setNameFilter] = useState({ value: "", key: "" });
  const [clientFilter, setClientFilter] = useState({ value: "", key: "" });
  const [deviceTypeFilter, setDeviceTypeFilter] = useState({
    value: "",
    key: "",
  });

  const updateDeviceList = async () => {
    try {
      const options = {
        path: "/devices",
        scope: "list:devices",
      };
      const newDeviceList = await callApi(options).then((res) => res.json());
      setDeviceList({
        ...deviceList,
        data: newDeviceList,
        error: null,
        loading: false,
      });
    } catch (error) {
      errorLogger(error);
      setDeviceList({
        ...deviceList,
        error,
        loading: false,
      });
    }
  };

  const updateTypesList = async () => {
    try {
      const options = {
        path: "/device_types",
        scope: "list:device_types",
      };
      const newList = await callApi(options).then((res) => res.json());
      setTypesList({
        ...typesList,
        data: newList,
        error: null,
        loading: false,
      });
    } catch (error) {
      errorLogger(error);
      setTypesList({
        ...typesList,
        error,
        loading: false,
      });
    }
  };

  const updateClientsList = async () => {
    try {
      const options = {
        path: "/clients",
        scope: "list:clients",
      };
      const newList = await callApi(options).then((res) => res.json());
      setClientsList({
        ...clientsList,
        data: newList,
        error: null,
        loading: false,
      });
    } catch (error) {
      errorLogger(error);
      setClientsList({
        ...clientsList,
        error,
        loading: false,
      });
    }
  };

  useEffect(() => {
    updateDeviceList();
    updateTypesList();
    updateClientsList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const uniqueNames = Array.from(new Set(deviceList.data.map((d) => d.name)));

  const clearAllFilters = () => {
    setEuiFilter({ value: "", key: "" });
    setNameFilter({ value: "", key: "" });
    setClientFilter({ value: "", key: "" });
    setDeviceTypeFilter({ value: "", key: "" });
  };

  const deviceRows = deviceList.data
    .filter(
      (d) =>
        (!euiFilter.key || d.eui === euiFilter.key) &&
        (!nameFilter.key || d.name === nameFilter.key) &&
        (!clientFilter.key || d.clientId === clientFilter.key) &&
        (!deviceTypeFilter.key || d.deviceTypeId === deviceTypeFilter.key)
    )
    .map((d) => {
      return (
        <tr key={d.id}>
          <td>{d.eui}</td>
          <td>{d.name}</td>
          <td>{d.clientName}</td>
          <td>{d.deviceTypeName}</td>
          <td>
            <button onClick={() => navigate(`/devices/${d.id}/edit`)}>
              <FontAwesomeIcon icon="fa-solid fa-pen-to-square" />
            </button>
            <button onClick={() => navigate(`/devices/${d.id}/alerts`)}>
              <FontAwesomeIcon icon="fa-solid fa-bell" />
            </button>
          </td>
        </tr>
      );
    });
  return (
    <div className="Devices page">
      <h2>Devices</h2>
      <button onClick={(_e) => navigate(`/devices/create`)}>New Device</button>
      <div>
        Show active devices?
        <select
          value={showActive}
          onChange={(e) => setShowActive(e.currentTarget.value)}
        >
          <option key="active" value='["active"]'>
            Active only
          </option>
          <option key="active and inactive" value='["active", "inactive"]'>
            Active and Inactive
          </option>
          <option key="inactive" value='["inactive"]'>
            Inactive only
          </option>
        </select>
      </div>
      <div>
        Show hidden devices?
        <select
          value={showHidden}
          onChange={(e) => setShowHidden(e.currentTarget.value)}
        >
          <option key="visible" value='["visible"]'>
            Visible only
          </option>
          <option key="visible and hidden" value='["visible", "hidden"]'>
            Visible and Hidden
          </option>
          <option key="hidden" value='["hidden"]'>
            Hidden only
          </option>
        </select>
      </div>
      <table>
        <thead>
          <tr key="headers">
            <th>EUI</th>
            <th>Name</th>
            <th>Client</th>
            <th>Type</th>
            <th></th>
          </tr>
          <tr key="filters">
            <td>
              <ComboBox
                aria-label="device-eui"
                selectedKey={euiFilter.key}
                inputValue={euiFilter.value || ""}
                onSelectionChange={(type) =>
                  setEuiFilter({ key: type, value: type })
                }
                onInputChange={(value) =>
                  setEuiFilter((prev) => ({
                    value: value,
                    key: value === "" ? "" : prev.key,
                  }))
                }
              >
                {deviceList.data.map((d) => (
                  <Item key={d.eui}>{d.eui}</Item>
                ))}
              </ComboBox>
            </td>
            <td>
              <ComboBox
                aria-label="device-name"
                selectedKey={nameFilter.key}
                inputValue={nameFilter.value || ""}
                onSelectionChange={(type) =>
                  setNameFilter({ key: type, value: type })
                }
                onInputChange={(value) =>
                  setNameFilter((prev) => ({
                    value: value,
                    key: value === "" ? "" : prev.key,
                  }))
                }
              >
                {uniqueNames.map((n) => (
                  <Item key={n}>{n}</Item>
                ))}
              </ComboBox>
            </td>
            <td>
              <ComboBox
                aria-label="device-client"
                selectedKey={clientFilter.key}
                inputValue={clientFilter.value || ""}
                onSelectionChange={(c) => {
                  setClientFilter({ key: c, value: c.clientName });
                }}
                onInputChange={(value) =>
                  setClientFilter((prev) => ({
                    value: value,
                    key: value === "" ? "" : prev.key,
                  }))
                }
                items={clientsList.data}
              >
                {(c) => <Item key={c.clientId}>{c.clientName}</Item>}
              </ComboBox>
            </td>
            <td>
              <ComboBox
                aria-label="device-type"
                selectedKey={deviceTypeFilter.key}
                inputValue={deviceTypeFilter.value || ""}
                onSelectionChange={(t) => {
                  setDeviceTypeFilter({
                    key: t,
                    value: t.name,
                  });
                }}
                onInputChange={(value) => {
                  setDeviceTypeFilter((prev) => ({
                    value: value ?? "",
                    key: value === "" ? "" : prev.key,
                  }));
                }}
              >
                {typesList.data.map((t) => (
                  <Item key={t.id}>{t.name}</Item>
                ))}
              </ComboBox>
            </td>
            <td>
              <Button onPress={() => clearAllFilters()}>Clear All</Button>
            </td>
          </tr>
        </thead>
        <tbody>{deviceRows}</tbody>
      </table>
    </div>
  );
}

export default DevicesList;
