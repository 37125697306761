import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const ValueMapConstraints = ({
  inputValueConstraints: constraints,
  setInputValueConstraints: setConstraints,
}) => {
  const [nameInput, setNameInput] = useState("");
  const [fieldInput, setFieldInput] = useState("");
  console.log(constraints);
  return (
    <div>
      <label>Entries</label>
      <ul>
        {constraints.map((item) => {
          return (
            <li key={item.name}>
              {JSON.stringify(item)}
              <button
                onClick={(e) => {
                  e.preventDefault();
                  const newConstraints = constraints.filter(
                    (c) => c.name !== item.name
                  );
                  setConstraints(newConstraints);
                }}
              >
                <FontAwesomeIcon icon="fa-solid fa-trash" />
              </button>
            </li>
          );
        })}
      </ul>
      <div>
        <div key={`parameters-valmap-name`}>
          <label>Name</label>
          <input
            id={`parameters-valmap-name-input`}
            type="text"
            value={nameInput}
            onChange={(e) => setNameInput(e.currentTarget.value)}
          />
        </div>
        <div key={`parameters-valmap-field`}>
          <label>Payload Field</label>
          <input
            id={`parameters-valmap-field-input`}
            type="text"
            value={fieldInput}
            onChange={(e) => setFieldInput(e.currentTarget.value)}
          />
        </div>
        <button
          onClick={(e) => {
            e.preventDefault();
            const newConstraints = constraints;
            newConstraints.push({ name: nameInput, payloadField: fieldInput });
            console.log(newConstraints);
            setConstraints(newConstraints);
            setNameInput("");
            setFieldInput("");
          }}
        >
          <FontAwesomeIcon icon="fa-solid fa-plus" />
        </button>
      </div>
    </div>
  );
};

export default ValueMapConstraints;
